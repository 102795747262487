<template>
  <div
    class="to-top d-flex align-center justify-center cursor-pointer"
    @click="goTo"
  >
    <v-icon color="white" :size="isMd ? '20px': '30px'">mdi-chevron-up</v-icon>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["fixedBtn", "locationDetails"]),
  },
  methods: {
    goTo() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.to-top {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 9;
  animation: fadeUpBtn 1s  forwards;
  transform: translateY(150px);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: $primary;
  transition: 0.3s !important;
  opacity: 0.8;

  &:hover {
    opacity: 1 !important;
  }

  @media (max-width: 960px) {
    bottom: 15px;
    left: 15px;    
    width: 50px;
    height: 50px;
    opacity: 0.9;
  }
}

@keyframes fadeUpBtn {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
