<template>
  <div class="filters">
    <template v-for="(category, i) in categories">
      <div
        v-if="category.is_active"
        class="filters__tab d-flex align-center justify-space-between"
        :class="isMd ? 'pa-1' : 'px-5 py-4'"
        :key="i"
        @click="replaceRoute(category.slug)"
      >
        <div class="d-flex align-center">
          <span class="font-15 black--text mx-2"> {{ category.name }}</span>
        </div>

        <transition name="fade" mode="out-in">
          <img
            v-if="category.active"
            :width="isMd ? '15px' : '25px'"
            :key="routeChanged"
            :src="require('@/assets/images/global/logo.png')"
            alt="MC"
          />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    routeChanged: false,
  }),
  watch: {
    "$route.params": {
      handler() {
        this.routeChanged = !this.routeChanged;
        this.checkActiveFilter();
      },
    },
  },
  methods: {
    replaceRoute(slug) {
      this.$router.replace(`/category/${slug}`);
    },
    checkActiveFilter() {
      this.categories.forEach((category) => {
        let categoryName = category.slug;
        if (categoryName == this.$route.params.title) {
          category.active = true;
        } else {
          category.active = false;
        }
      });
      this.$emit("filterHandler");
    },
  },
  created() {
    this.categories.forEach((category) => {
      let categoryName = category.slug.replaceAll(" ", "_");
      if (categoryName == this.$route.params.title) {
        category.active = true;
      } else {
        category.active = false;
      }
    });
  },
};
</script>

<style lang="scss">
.filters {
  position: sticky;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  top: 100px;

  &__tab {
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    cursor: pointer;
    transition: 0.3s;
    span {
      transition: 0.3s;
    }
    &:hover {
      background: $primary;
      span {
        color: white;
      }
    }
    &:last-child {
      border-bottom: unset;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    flex-wrap: wrap;
    border: unset;
    justify-content: center;
    &__tab {
      border: 1px solid rgba(0, 0, 0, 0.26);
      border-radius: 2px;
      margin: 4px;
      &:last-child {
        border: 1px solid rgba(0, 0, 0, 0.26);
      }
    }
  }
}
</style>
