<template>
  <div class="products">
    <div class="max-width">
      <!-- items -->
      <div class="mb-3" v-if="!isMd">
        <span class="font-32 font-500 black--text uppercase">{{
          categoryTitle
        }}</span>
      </div>
      <v-row v-if="!loading">
        <v-col lg="3" cols="12">
          <Filters
            v-if="categories.length > 0"
            :categories="categories"
            @filterHandler="filterHandler"
          />
        </v-col>
        <v-col lg="9" cols="12">
          <v-row class="list-scroll">
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mb-3"
              v-for="(item, i) in items"
              :key="i"
            >
              <product-card
                @reRender="rerenderHandler(item)"
                :key="reRenderItem"
                :item="item"
              ></product-card>
            </v-col>
          </v-row>

          <!-- no items -->
          <div class="text-center my-8" v-if="items.length == 0">
            <span class="font-16 font-400"> {{ $t("global.noItems") }} </span>
          </div>
        </v-col>
      </v-row>

      <!-- loading skeletion -->
      <v-row v-if="loading">
        <v-col md="3" v-if="!isMd">
          <skeleton-card type="experience"></skeleton-card>
        </v-col>
        <v-col md="9" cols="12">
          <v-row>
            <v-col
              v-for="(item, i) in [1, 1, 1, 1, 1, 1]"
              cols="12"
              sm="6"
              md="4"
              :key="i"
            >
              <skeleton-card type="experience" :key="i"></skeleton-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <back-to-top v-if="isMd"></back-to-top>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonCard from "../../components/base/SkeletonCard.vue";
import Filters from "./components/Filters.vue";
import ProductCard from "./components/ProductCard.vue";
import { fetchCategories } from "@/services/Categories";
import { fetchProducts } from "@/services/Products";
import BackToTop from "./components/BackToTop.vue";
export default {
  components: {
    SkeletonCard,
    ProductCard,
    Filters,
    BackToTop,
  },
  data: () => ({
    items: [],
    categories: [],
    loading: false,
    reRenderItem: false,
    params: {},
    categoryTitle: "",
  }),
  computed: {
    ...mapGetters(["translate"]),
    locationID() {
      return localStorage.getItem("locationID");
    },
  },
  methods: {
    tryAgainHandler() {},
    rerenderHandler(item){
      item.count = 1
      this.reRenderItem = !this.reRenderItem
    },
    async filterHandler() {
      if (this.$route.params.title) {
        let category = this.categories.find(
          (cat) => cat.slug == this.$route.params.title
        );
        if (category) {
          this.params.category_id = category.id;
          this.categoryTitle = category.name;
        }
        await this.getProducts();
      }
    },
    async getCategories() {
      this.categories = await fetchCategories(this.locationID);
    },
    async getProducts() {
      this.items = await fetchProducts(this.params);
      this.items.forEach((item) => (item.count = 1));
      setTimeout(async () => {
        await this.getProducts();
      }, 300000);
    },
  },
  async created() {
    this.params.location_id = this.locationID;
    await this.getCategories();
    if (this.$route.params.title) {
      await this.filterHandler();
    } else {
      await this.getProducts();
    }
  },
};
</script>

<style lang="scss">
.products {
  margin: 50px 0px 100px 0px;
}
</style>
